import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch, useHistory } from "react-router-dom";
import ContentMain from "@/routes/contentMain/contentMain";
import Login from "@/view/login/login";
import blueWhale from '@/view/cloudAhart/blueWhale'  //蓝鲸云图
import PrivateRoute from "./utils/PrivateRoute";
import { historyState } from "@/utils/memory";
import LjScreen from '@/view/cloudAhart/LjScreen';
import Entrepreneurship from '@/view/cloudAhart/Entrepreneurship';
import Competition from '@/view/cloudAhart/competition';
import Bi from '@/view/cloudAhart/bi';
import Ecommerce from '@/view/cloudAhart/e-commerce';
import AJAX from "@/api/api";
import redux from '@/redux/store';
import { updateTeacherUserInfo } from '@/redux/actions/teacherUserInfo';
function App() {
  const history = useHistory();

  useEffect(() => {
    historyState.set(history);
    let href = window.location.href
    if (href.includes('/e_commerce') || href.includes('/bi')) {
      return
    }
    AJAX.login.getUserInfo()
      .then(async (res) => {
        redux.dispatch(updateTeacherUserInfo(res.data));
        localStorage.setItem('teacherUserInfo', JSON.stringify(res.data));
      })
  }, [history]);
  return (
    <Switch>
      <PrivateRoute path="/login" component={Login}></PrivateRoute>
      <PrivateRoute path="/blueWhale" component={blueWhale}></PrivateRoute>
      <PrivateRoute path="/ljscreen" component={LjScreen}></PrivateRoute>
      <PrivateRoute path="/Entrepreneurship" component={Entrepreneurship}></PrivateRoute>
      <PrivateRoute path="/Entrepreneurship_cn" component={Entrepreneurship}></PrivateRoute>
      <PrivateRoute path="/competition" component={Competition}></PrivateRoute>
      <Route path="/e_commerce" component={Ecommerce}></Route>
      <Route path="/bi" component={Bi}></Route>

      <Route path="/" component={ContentMain}></Route>
    </Switch>
  );
}

export default App;
