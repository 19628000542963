import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import * as echarts from 'echarts';
import { bigNumberTransform } from '@/utils/utils';

const initXAxisData = () => [];
const initOptions = () => ({
  grid: {
    top: 10,
    left: 130,
    right: 60,
    bottom: 40
  },
  xAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: "#2193F8",
      }
    },
  },
  yAxis: {
    type: 'category',
    data: ['广东培正学院', '邵阳学院', '攀枝花学院', '四川旅游学院', "广西民族师范学院"],
    axisLine: {
      lineStyle: {
        color: "#2193F8",
      }
    }
  },
  series: [
    {
      barWidth: '10px',
      itemStyle: {
        normal: {
          barBorderRadius: [0, 5, 5, 0]
        }
      },
      data: [
        {
          value: 100,
          itemStyle: {
            color: '#55A6F5'
          },
          with: 10,
        },
        {
          value: 200,
          itemStyle: {
            color: '#0C6DD7'
          }
        },
        {
          value: 90,
          itemStyle: {
            color: '#F9B03D'
          }
        },
        {
          value: 120,
          itemStyle: {
            color: '#F9B03D'
          }
        },
        {
          value: 150,
          itemStyle: {
            color: '#FE5C64'
          }
        },
      ],
      type: 'bar',
    }
  ]
});

export default ({ echartsInfo, heights = 220 }) => {
  let isheights = (Number(document.body.clientHeight) >= 1080) ? true : false
  let container = useRef(null);
  let echart = useRef(null);
  let colors = useMemo(() => ["#0C52F2", "#0FBB48", "#EC6C49", "#04D2F7", "#E5C222", "#2F53E1", "#ED7E7A"], []);
  let [xAxisData, setXAxisData] = useState(initXAxisData);
  let [options, setOptions] = useState(initOptions);
  let [echartsheights, setEchartsheights] = useState(heights);

  useEffect(() => {
    if (echartsInfo) {
      let data = [], names = [], heights = document.body.clientHeight > 700, widths = document.body.clientWidth > 1366
      echartsInfo.forEach((item, index) => {
        if (index <= 4) {
          data.push(item.money)
          names.push(item.schoolName)
        }
      });
      names.reverse()
      options = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 0,
          left: document.body.clientWidth > 768 ? 190 : 90,
          right: document.body.clientWidth > 768 ? 30 : 15,
          bottom: widths ? 40 : 20
        },
        xAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: "#FFFFFF",
              width: .3
            }
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          nameTextStyle: {// 名称样式
            fontSize: 12,
            color: '#2193F8',
            padding: [28, 0, 0, widths ? 0 : 0]
          },
          axisLabel: {
            textStyle: {
              color: '#2193F8',
              fontSize: document.body.clientWidth > 1200 ? 14 : document.body.clientWidth > 768 ? 12 : 10,
            },
            formatter: (params, index) => {
              // console.log(index % 2,index);
              // if (document.body.clientWidth <= 768 && !(index % 2)) {
              //   return ''
              // } else {
              //   return bigNumberTransform(params)
              // }
              return bigNumberTransform(params)

            },
            interval: 3,
          },
        },
        yAxis: {
          type: 'category',
          data: names,
          axisLine: {
            lineStyle: {
              color: "#FFFFFF",
              width: .3
            }
          },
          // offset: 5,
          axisTick: {
            show: false
          },
          axisLabel: {
            rotate: document.body.clientWidth > 768 ? 0 : 30,
            textStyle: {
              color: '#2193F8',
              fontSize: document.body.clientWidth > 1200 ? 14 : document.body.clientWidth > 768 ? 12 : 10,
            },
          },
        },
        series: [
          {
            barWidth: heights ? '8px' : '5px',
            showBackground: true,
            backgroundStyle: {
              borderRadius: [0, 5, 5, 0]
            },
            itemStyle: {
              normal: {
                barBorderRadius: [0, 5, 5, 0],
              }
            },
            data: [
              {
                value: data[4],
                itemStyle: {
                  color: '#55A6F5'
                },
                with: 10,
              },
              {
                value: data[3],
                itemStyle: {
                  color: '#0C6DD7'
                }
              },
              {
                value: data[2],
                itemStyle: {
                  color: '#01F1F8'
                }
              },
              {
                value: data[1],
                itemStyle: {
                  color: '#F9B03D'
                }
              },
              {
                value: data[0],
                itemStyle: {
                  color: '#FE5C64'
                }
              },
            ],

            type: 'bar',
          }
        ]
      }
      setOptions(options)
    }

  }, [echartsInfo]);

  useEffect(() => {
    if (!echart.current) echart.current = echarts.init(container.current);
    // echart.current.resize({ height: Number(document.body.clientWidth) > 375 ? echartsheights : 150 })
    echart.current.setOption(options);

  }, [options, echartsheights]);

  return (
    <div className="hatch-echarts">
      <div
        ref={container}
        className=""
        style={{ height: `100%` }}

      ></div>
    </div>
  );
};
