import React from "react";
import LoadableComponent from "@/utils/LoadableComponent";
import "./contentMain.css";
import {
  Layout,
  Menu,
  Breadcrumb,
  ConfigProvider,
  Dropdown,
  Button,
} from "antd";
import loginOut from "@/utils/loginOut";
import AJAX from "@/api/api";
import { debounce, getStudentSideUrl } from "@/utils/utils";
import Cookie from "@/utils/cookie";
import { Link } from "react-router-dom";

// import '@/assets/font_icon/iconfont.css'
import CN from "antd/es/locale/zh_CN";
import redux from "@/redux/store";
import { queryTeacherUserInfo } from "@/redux/actions/teacherUserInfo";
import menufunction from "./menuList";
import {
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { compressRoutes } from "@/utils/dom";
import {
  storage,
  getSearchParamsInStorage,
  setSearchParamsInStorage,
  clearSearchParamsInStorage,
} from "@/utils/dom";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

let Router = LoadableComponent(() => import("../Router"));
//三板块主页面
class ContentMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jurisdiction:
        redux.getState().teacherUserInfo &&
        redux.getState().teacherUserInfo.roleName == "train_teacher",
      collapsed: false,
      logoImg: "1",
      logoImgS: "1",
      teacherUserInfo: null,
      yxyp: ["lj.513.com", "bsuc.513.com", "szy.513online.top", "localhost"],
      competitonOptionList: [],
      isWx: false
    };
    this.count = 0;
    this.breadcrumbData = [];
  }

  //检测导航栏
  detectionMenu = (menuList) => {
    let pathArr = this.props.location.pathname.split("/"),
      breadcrumbData = [];
    pathArr.shift();
    // console.log(menuList, 'menuList')
    // const pathSnippets = this.props.location.pathname.split("/").filter((i) => i);
    // const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    //   const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    //   return (
    //     <Breadcrumb.Item key={url}>
    //       <Link to={url}>{breadcrumbNameMap[url]}</Link>
    //     </Breadcrumb.Item>
    //   );
    // });
    // const breadcrumbItems = [
    //   <Breadcrumb.Item key="home">
    //     <Link to="/">Home</Link>
    //   </Breadcrumb.Item>,
    // ].concat(extraBreadcrumbItems);

    for (let item of menuList) {
      if (`/${pathArr[this.count]}` === item.path) {
        let to;
        let flag =
          this.breadcrumbData[this.count] &&
          this.breadcrumbData[this.count].path === item.path;
        if (flag) {
          to = this.breadcrumbData[this.count].to;
        } else {
          if (pathArr.length - 1 === this.count) {
            let { pathname, search } = this.props.location;
            to = `${pathname}${search}`;
          }
        }
        if (item.children) {
          this.count++;
          breadcrumbData.unshift(...this.detectionMenu(item.children));
        }
        breadcrumbData.unshift({ ...item, to });
        this.count = 0;
        return breadcrumbData;
      }
    }
    return [];
  };

  //导航
  renderMenu = () => {
    const menuLs = menufunction();
    let pathArr = this.props.location.pathname.split("/"),
      openKey = pathArr.slice(1, 2)[0],
      selectedKey = pathArr.slice(2, 3)[0] || pathArr.slice(1, 2)[0];
    return (
      <Menu
        className="teacherMenu"
        theme="dark"
        selectedKeys={[`/${selectedKey}`]}
        mode="inline"
        style={{ background: "none" }}
        defaultOpenKeys={["/" + openKey]}
      >
        {menuLs.length &&
          menuLs.map((item) => {
            if (item.notRenderChild) {
              return (
                <Menu.Item
                  key={item.path}
                  icon={
                    <>
                      <span className="top-mask"></span>
                      <span className="bottom-mask"></span>
                    </>
                  }
                  onClick={() => {
                    this.handleMenuClick(item.path, item.path);
                  }}
                >
                  <span className="menu-item-content">
                    <span className={`icon ${item.icon}`}></span>
                    <span className="menu-title-text">{item.text}</span>
                  </span>
                </Menu.Item>
              );
            }
            return (
              <SubMenu
                key={item.path}
                title={
                  <span className="menu-item-content">
                    <span className={`icon ${item.icon}`}></span>
                    <span className="menu-title-text">{item.text}</span>
                  </span>
                }
                icon={
                  <>
                    <span className="top-mask"></span>
                    <span className="bottom-mask"></span>
                  </>
                }
              >
                {item.children.map((list) => {
                  return (
                    <Menu.Item
                      key={list.path}
                      icon={
                        <>
                          <span className="top-mask"></span>
                          <span className="bottom-mask"></span>
                        </>
                      }
                      onClick={() => {
                        this.handleMenuClick(list.path, item.path + list.path);
                      }}
                    >
                      <span className="menu-item-content">
                        <span className="menu-title-text">{list.text}</span>
                      </span>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          })}
      </Menu>
    );
  };

  //面包屑的叠加
  renderNavList = () => {
    this.breadcrumbData = this.detectionMenu(menufunction());
    console.log(
      this.breadcrumbData,
      compressRoutes(menufunction()),
      "breadcrumbData"
    );
    return this.breadcrumbData.map((item, index) => (
      <Breadcrumb.Item
        key={index}
        style={item.to ? { cursor: "pointer" } : {}}
        onClick={() => {
          this.breadcrumbClick(item);
        }}
      >
        {item.text}
      </Breadcrumb.Item>
    ));
  };

  computedBreadcrumb = () => {
    const { location } = this.props;
    const { pathname, search } = location;
    const menuList = menufunction();
    const breadcrumbNameMap = compressRoutes(menuList);
    const pathSnippets = pathname.split("/").filter((i) => i);

    const needSearchPath = [
      "/teaching/trainTeaching/passignment",
      "/teaching/trainTeaching/assess",
    ];

    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      let url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      if (index === pathSnippets.length - 1) {
        if (search) {
          setSearchParamsInStorage(url, search);
        }
      }

      const text = breadcrumbNameMap[url];

      const curUrlStorageSearch = getSearchParamsInStorage(url);
      const isDisabledLink =
        (needSearchPath.includes(url) && !curUrlStorageSearch) || index === 0;
      return (
        <Breadcrumb.Item key={url}>
          {isDisabledLink ? (
            text
          ) : (
            <Link to={url + curUrlStorageSearch}>{text}</Link>
          )}
        </Breadcrumb.Item>
      );
    });
    // console.log(breadcrumbNameMap, 'breadcrumbNameMap')

    // const breadcrumbItems = [
    //   <Breadcrumb.Item key="home">
    //     <Link to="/">Home</Link>
    //   </Breadcrumb.Item>,
    // ].concat(extraBreadcrumbItems);

    return extraBreadcrumbItems;
  };

  breadcrumbClick = (item) => {
    if (item.to) this.props.history.push(item.to);
  };

  //跳转页面
  handleMenuClick = (selectkey, path) => {
    this.setState(
      {
        selectedKeys: [selectkey],
      },
      () => {
        this.props.history.push(path);
      }
    );
  };

  // 跳转学生端
  handleStudentSide = () => {
    let teacherUserInfo = JSON.parse(localStorage.getItem("teacherUserInfo"));
    let { protocol, host } = window.location;
    if (teacherUserInfo.schoolId !== 1) {
      if (Cookie.getCookie("simulated_token")) {
        AJAX.login.exitSimulation().then((res) => {
          if (res.code == 1) {
            window.location.href = `${protocol}//${host}/home`;
          }
        });
      } else {
        window.location.href = `${protocol}//${host}/home`;
      }
    } else {
      const studentSideUrl = getStudentSideUrl();
      window.location.href = `${protocol}//${studentSideUrl}/home`;
    }
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  recordNumber = () => {
    if (process.env.NODE_ENV == "development") {
      return "粤ICP备19058570号-1";
    } else {
      return "粤ICP备19126219号";
    }
  };
  getCompetitonOptionList() {
    AJAX.competition.getCompetitonOptionList().then((res) => {
      if (res.code == 1) {
        this.setState({
          competitonOptionList: res.data,
        });
      }
    });
  }

  cloudMap = () => {
    let info = JSON.parse(localStorage.getItem("teacherUserInfo"));
    const schoolCatalogueList = info && info.schoolCatalogueList ? info.schoolCatalogueList : []
    return (
      <React.Fragment>
        {schoolCatalogueList.map((item) => {
          if (item.dataPath && item.dataPath.includes('http')) {
            return (
              <a
                href={item.dataPath}
                target="_blank"
                key={item.dataName}
              >
                {item.dataAbbreviation}
              </a>
            );
          }
          return (
            <Link
              to={item.dataPath}
              target="_blank"
              key={item.dataName}
            >
              {item.dataAbbreviation}
            </Link>
          );
        })}
      </React.Fragment>
    )
  }

  componentDidMount() {
    // this.props.queryTeacherUserInfo();
    setTimeout(() => {
      let data = JSON.parse(localStorage.getItem("teacherUserInfo"));
      if (data) {
        this.setState({
          logoImg: data.transparentLogo || "",
          logoImgS: data.transparentIco || "",
        });
      } else {
        this.setState({
          logoImg: "",
          logoImgS: "",
        });
      }
      // this.getCompetitonOptionList();
      // console.log(this.state.logoImg);
    }, 300);
    let ua = window.navigator.userAgent.toLowerCase()
    // console.log(ua);
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.setState({ isWx: true })
    }
  }

  render() {
    let { collapsed, logoImg, logoImgS, competitonOptionList, isWx } = this.state;
    const { teacherAvatar, teacherName, schoolId } = this.props;
    return (
      <Layout hasSider className="ContentMain">
        <Sider
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
          }}
          collapsed={collapsed}
        >
          <div className="logo">
            {logoImg != 1 && !collapsed ? (
              <img
                src={
                  logoImg ||
                  `assets/images/${collapsed ? "logo1" : "logo2"}.png`
                }
                className={logoImg ? "" : "default_img"}
              />
            ) : null}
            {logoImgS != 1 && collapsed ? (
              <img
                src={
                  logoImgS ||
                  `assets/images/${collapsed ? "logo1" : "logo2"}.png`
                }
                className={logoImgS ? "" : "default_img"}
              />
            ) : null}
          </div>
          {this.renderMenu()}
        </Sider>
        <Layout className={{ "site-layout": true, collapsed }}>
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 }}
          >
            <div>
              <div className="header-left">
                {collapsed ? (
                  <MenuUnfoldOutlined
                    style={{ fontSize: 24, color: "#B3B3B3" }}
                    onClick={this.toggle}
                  />
                ) : (
                  <MenuFoldOutlined
                    style={{ fontSize: 24, color: "#B3B3B3" }}
                    onClick={this.toggle}
                  />
                )}
              </div>
              <div className="header-title">
                {/* <a href="https://513-edu-image.oss-cn-shenzhen.aliyuncs.com/resources/01-fullscreen.png" target='_blank'>教务数据云图</a> */}
                <Link to="/blueWhale" target="_blank">
                  教务数据云图
                </Link>
                {this.cloudMap()}

              </div>
            </div>
            <div className="header-right">
              <div className="message"></div>
              <div className="user">
                <div className="photo">
                  <img
                    src={
                      teacherAvatar
                        ? teacherAvatar
                        : require("@/assets/images/public/head_default.png")
                    }
                  ></img>
                </div>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => this.handleStudentSide()}>
                        前往学生端
                      </Menu.Item>
                      <Menu.Item onClick={() => loginOut(0)}>
                        退出登录
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomCenter"
                  arrow
                >
                  <div className="user-name">
                    <p>{teacherName}</p>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb separator=">" style={{ margin: "16px 0" }}>
              {/* {this.renderNavList()} */}
              {this.computedBreadcrumb()}
            </Breadcrumb>
            <div className={`site-layout-background ${isWx ? 'isWx' : ''}`}>
              <ConfigProvider locale={CN}>
                <Router />
              </ConfigProvider>
            </div>
            <Footer style={{ textAlign: "center", color: "#999", fontSize: "12px" }}>
              Copyright ©深圳市伍壹叁数字科技有限公司 版权所有
              <a
                href="https://beian.miit.gov.cn/"
                style={{ color: "#999", fontSize: "12px" }}
                target="_blank"
              >
                {this.recordNumber()}
              </a>
              <span style={{ marginLeft: '10px' }}>版本号：v1.6.1</span>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = ({ teacherUserInfo }) => {
  teacherUserInfo = teacherUserInfo || {};
  return {
    teacherName: teacherUserInfo.name,
    teacherAvatar: teacherUserInfo.photo,
    schoolId: teacherUserInfo.schoolId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  queryTeacherUserInfo: () => dispatch(queryTeacherUserInfo()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentMain);
